
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import StatusesList from '@/components/pages/shared/StatusesList.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { colorPaletteOptionsOutline } from '@/definitions/_general/_data/colorPaletteOptions'

export default defineComponent({
  components: {
    PageBlock,
    DetailsHero,
    StatusesList,
    WhiteBlock,
  },
  setup() {
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Contacts',
        name: 'base.contacts',
      },
      {
        label: 'Settings',
        name: 'base.contacts.settings',
      },
      {
        label: 'Contact types',
      },
    ])

    const types = ref([
      {
        isDefault: false,
        color: 'green',
        outline: true,
        name: 'Lead',
      },
      {
        isDefault: false,
        color: 'orange',
        outline: true,
        name: 'Customer',
      },
      {
        isDefault: false,
        color: 'violet',
        outline: true,
        name: 'Subscriber',
      },
      {
        isDefault: false,
        color: 'blue',
        outline: true,
        name: 'Employee',
      },
      {
        isDefault: false,
        color: 'red',
        outline: true,
        name: 'Student',
      },
      {
        isDefault: false,
        color: 'cyan',
        outline: true,
        name: 'Prospect',
      },
      {
        isDefault: false,
        color: 'brown',
        outline: true,
        name: 'Subcontractor',
      },
    ])

    return {
      breadcrumbsLink,
      types,
      colorPaletteOptionsOutline,
    }
  },
})
